import React from "react"
import Layout from "../retech/components/layout/Layout"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

export default function TermsPage({ data }) {
    const terms = data.terms.nodes
    const metadata = data.metadata.nodes
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const urlimage = typeof window !== 'undefined' ? window.location.hostname : '';
    return (
        <Layout>
            <Helmet>
                <title>Terms</title>


                <meta name="description" content={data.metadata.nodes[0].TermsMetaDescription} />
                <meta name="keywords" content={data.metadata.nodes[0].Termskeywords} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={data.metadata.nodes[0].TermsMetaTitle} />
                <meta property="og:description" content={data.metadata.nodes[0].TermsMetaDescription} />
                <meta property="og:image" content={urlimage + "/logo.svg"} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={url} />
                <meta property="twitter:title" content={data.metadata.nodes[0].TermsMetaTitle} />
                <meta property="twitter:description" content={data.metadata.nodes[0].TermsMetaDescription} />
                <meta property="twitter:image" content={urlimage + "/logo.svg"} />
            </Helmet>
            <div className="container-fluid bg-light terms py-5">
                <div className="container">
                    <div className="col-md-12 py-5">
                        <div className="row">

                            {terms.map((node, index) => {
                                return (
                                    <>
                                        <div className="col-md-7" key={index}>
                                            <h1 className="font-weight-bolder">
                                                {node.TermsWidgetTitle}
                                            </h1>
                                            <p className="text-muted">
                                                {node.TermsWidgetIntro}
                                            </p>
                                        </div>
                                    </>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-4">
                <div className="col-md-12">
                    <h5 className="font-weight-bolder text-muted">
                        GENERAL TERMS AND CONDITIONS OF PURCHASE FOR SERVICES, MATERIAL &
                        EQUIPMENT
          </h5>

                    {terms.map((node, index) => {
                        return (
                            <>

                                {node?.Terms?.length > 0 &&
                                    node.Terms.map((node2, i) => (
                                        <>
                                            <div ClassName="terms" key={i + 1}>
                                                <h5 className="font-weight-bolder text-muted">{node2.TermsTitle}</h5>
                                                <p className="text-muted font-size-small">
                                                    {node2.TermsDescription}
                                                </p>
                                            </div>
                                        </>
                                    ))}

                            </>
                        )
                    })}

                </div>
            </div>
        </Layout >
    )
}
export const query = graphql`
query{


terms: allTermsJson {
    nodes {
      TermsWidgetIntro
      TermsWidgetTitle
      Terms {
        TermsDescription
        TermsTitle
      }
    }
  }
metadata: allMetadataJson(filter: {TermsMetaTitle: {ne: null}}) {
    nodes {
      TermsMetaDescription
      TermsMetaTitle
      Termskeywords
    }
  }
}
`